import { useFormatter } from "../../../helpers/i18n"
import "./DiscoverOnPoint.scss"
import ContainerMain from "pages/common/ContainerMain"
import SectionTitle from "pages/common/SectionTitle"
import SubTitle from "pages/common/SubTitle"

export default function DiscoverOnPoint() {
  const { __ } = useFormatter()

  return (
    <ContainerMain>
      <div className="rKRZYKFcqr">
        <div className="fZpgIzecKn">
          <SectionTitle title={__({ defaultMessage: "Discover OnPoint" })} />
          <SubTitle nColClass="col-lg-10">
            {__({
              defaultMessage:
                "We always put our clients first. We pioneer in building SEA's leading ecosystem enabling e-commerce. Our promise is to create impactful values for our clients and partners by developing sustainable, seamless customer-centric solutions to unlock growth opportunities and achieve measurable results.",
            })}
          </SubTitle>
        </div>
      </div>
    </ContainerMain>
  )
}
